import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';
import config from './config';
import { withRouter } from 'react-router';
import { browserHistory } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { prefixLng, Constants } from './util';
import Header from './components/Header';
import Footer from './components/Footer';
import HelpBox from './components/HelpBox';
import PageNotFound from './controllers/PageNotFound';
import Seo from './components/Seo';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import TagManager from 'react-gtm-module';
import { isMobile } from 'react-device-detect';
import shortid from 'shortid';

const tagManagerArgs = {
    gtmId: 'GTM-TKP5ND6',
    dataLayerName: 'PageDataLayer'
};

// const tagManagerArgsHome = {
//     dataLayer: {
//         userId: '001',
//         userProject: 'souvenaid',
//         page: 'home'
//     },
//     dataLayerName: 'PageDataLayer'
// };

TagManager.initialize(tagManagerArgs);
// TagManager.dataLayer(tagManagerArgsHome);

const Controllers = {
    Home: lazy(() => import('./controllers/Home')),
    About: lazy(() => import('./controllers/About')),
    AboutVideo: lazy(() => import('./controllers/AboutVideo')),
    BrainCare: lazy(() => import('./controllers/BrainCare')),
    DegenerativeBrain: lazy(() => import('./controllers/DegenerativeBrain')),
    Stores: lazy(() => import('./controllers/Stores')),
    ContactUs: lazy(() => import('./controllers/ContactUs')),
    SiteMap: lazy(() => import('./controllers/SiteMap')),
    TermsAndConditions: lazy(() => import('./controllers/TermsAndConditions')),
    PrivacyPolicy: lazy(() => import('./controllers/PrivacyPolicy')),
    Cookies: lazy(() => import('./controllers/Cookies'))
};
export var app = null;

export const App = withRouter(
    withTranslation('Common')(
        class extends React.Component {
            constructor(props) {
                super(props);
                this.state = {
                    currentLanguage: props.i18n.options.fallbackLng.includes(props.i18n.language)
                        ? props.i18n.language
                        : props.i18n.options.fallbackLng[0],
                    ready: false,
                    pageTitle: '',
                    onMobileMenu: false
                };

                i18n.on('languageChanged', lng => {
                    this.setState({ currentLanguage: lng });
                });

                this.isChangingLang = false;
                this.config = config;
                this.formatPath = null;
                this.isMobile = false;

                // expose app instance
                app = this;

                // listen to languageChanged event
                // to manintain consistent URL with the language
                i18n.on('languageChanged', lng => {
                    const history = this.props.history;
                    const pathnames = history.location.pathname.split('/');

                    if (!Constants.allowed_locales.includes(i18n.language)) {
                        i18n.changeLanguage(Constants.default_locale);
                    }

                    if (Constants.default_locale === lng) {
                        Constants.allowed_locales.map(item => {
                            if (window.location.pathname.includes('/' + item)) {
                                let newUrl = window.location.pathname.replace('/' + item, '');
                                history.replace(newUrl);
                            }
                        });
                    } else {
                        // Add the /en in the URL
                        // @todo: add elseif for more than 2 langs because this works only for default + 1 more language
                        let newUrl = '/' + lng + window.location.pathname;
                        history.replace(newUrl);
                    }
                });
            }

            componentDidMount() {
                window.addEventListener('resize', this._onResize.bind(this));

                this._onResize();
            }

            componentDidUpdate() {}

            componentWillReceiveProps(newProps) {
                const { pathname: oldPathname } = this.props.location;
                const { pathname: newPathname, search } = newProps.location;
                //NOTE: Temp Disable
                // make sure current language is consistent with URL prefix
                // if (oldPathname && oldPathname !== newPathname) {
                //     const lng = newPathname.split('/')[1];
                // if (oldPathname.split('/')[1] !== lng) {
                //     this.isChangingLang = false;
                //     if (lng !== i18n.language) {
                //         i18n.changeLanguage(lng);
                //     }
                // }
                // }
            }

            _onResize() {
                this.isMobile = window.innerWidth < 768;

                if (this.isMobile) {
                    return true;
                }
            }

            _onMobileMenu(active) {
                var root = document.getElementsByTagName('html')[0];
                var onMobileMenuClass = 'menu-active';
                if (active) {
                    if (!this.hasClass(root, onMobileMenuClass)) {
                        root.classList.add(onMobileMenuClass);
                    }
                } else {
                    if (this.hasClass(root, onMobileMenuClass)) {
                        root.classList.remove(onMobileMenuClass);
                    }
                }
            }

            hasClass(ele, cls) {
                return ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
            }

            redirect(path) {
                this.props.history.push(prefixLng(path));
            }

            render() {
                const {
                    Home,
                    DegenerativeBrain,
                    BrainCare,
                    About,
                    AboutVideo,
                    Stores,
                    ContactUs,
                    SiteMap,
                    TermsAndConditions,
                    PrivacyPolicy,
                    Cookies,
                    NotFound
                } = Controllers;
                var loadingBottle = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 353.3 402.3" xmlspace="preserve">
                                <path class="bottle-outline" d="M209.6,192.5c2.9-10.3,11.1-21.4,7-36c-4.3-15-10.9-16-10.9-16s3.1-3.3,3-15s-3.3-17.9-11.9-19         c-12.6-1.3-25.2-1.3-37.8,0c-8.6,1.1-11.8,7.3-11.9,19c-0.1,11.7,3,15,3,15s-6.7,1-10.9,16s4,25.6,7,36s-0.9,32-2,34.9s-7,7.9-7,26         c0.7,31.1,26.9,28,26.9,28h27.9c0,0,26.2,3.1,26.9-28c0-18.1-5.8-23-7-26S206.7,202.8,209.6,192.5z"></path>
                                <defs>
                                    <clipPath id="bottle-clip-shape">
                                        <path class="bottle-outline" d="M209.6,192.5c2.9-10.3,11.1-21.4,7-36c-4.3-15-10.9-16-10.9-16s3.1-3.3,3-15s-3.3-17.9-11.9-19                         c-12.6-1.3-25.2-1.3-37.8,0c-8.6,1.1-11.8,7.3-11.9,19c-0.1,11.7,3,15,3,15s-6.7,1-10.9,16s4,25.6,7,36s-0.9,32-2,34.9                         s-7,7.9-7,26c0.7,31.1,26.9,28,26.9,28h27.9c0,0,26.2,3.1,26.9-28c0-18.1-5.8-23-7-26S206.7,202.8,209.6,192.5z"></path>
                                    </clipPath>
                                </defs>
                    
                                <g clip-path="url(#bottle-clip-shape)">
                                    <path class="bottle-fluid" d="M455.71,517.09c-9.81,0-9.81-5.42-19.62-5.42s-9.81,5.42-19.62,5.42-9.81-5.42-19.62-5.42-9.81,5.42-19.63,5.42-9.8-5.42-19.61-5.42-9.81,5.42-19.62,5.42-9.81-5.42-19.62-5.42-9.81,5.42-19.62,5.42-9.81-5.42-19.62-5.42-9.81,5.42-19.62,5.42-9.81-5.42-19.62-5.42-9.81,5.42-19.62,5.42-9.82-5.42-19.63-5.42-9.81,5.42-19.62,5.42-9.81-5.42-19.62-5.42-9.81,5.42-19.62,5.42-9.81-5.42-19.62-5.42c-.5,0-1,0-1.41.05v-187c.44,0,.87-.05,1.36-.05,9.81,0,9.81,5.42,19.62,5.42s9.81-5.42,19.62-5.42,9.81,5.42,19.62,5.42,9.81-5.42,19.62-5.42,9.81,5.42,19.62,5.42,9.82-5.42,19.63-5.42,9.81,5.42,19.62,5.42,9.81-5.42,19.62-5.42,9.81,5.42,19.63,5.42,9.81-5.42,19.62-5.42,9.81,5.42,19.62,5.42,9.81-5.42,19.62-5.42,9.81,5.42,19.62,5.42,9.82-5.42,19.63-5.42,9.81,5.42,19.62,5.42,9.82-5.42,19.63-5.42,9.81,5.42,19.63,5.42c9.35,0,9.79-4.92,18.3-5.38v187C465.51,512.18,465.06,517.09,455.71,517.09Z"></path>
                                </g>
                            </svg>`;

                const suspenseFallback = (
                    <div>
                        <div className="loader-container">
                            {/* <div className="loader"></div> */}
                            <p dangerouslySetInnerHTML={{ __html: loadingBottle }}></p>
                        </div>
                    </div>
                );

                this.isMobile = window.innerWidth < 768;

                const renderWithSuspense = (Component, pageData) => ({ match: { params } }) => (
                    <Suspense fallback={suspenseFallback}>
                        <Component urlParams={params} pageData={pageData} isMobile={this._onResize()} />
                    </Suspense>
                    //  <p dangerouslySetInnerHTML={{ __html: loadingBottle }}></p>
                );

                const routesObj = [
                    // {
                    //     path: '/home',
                    //     exact: true,
                    //     component: renderWithSuspense(Home)
                    // },
                    {
                        path: '/dementia/:anchor?',
                        component: renderWithSuspense(DegenerativeBrain)
                    },
                    {
                        path: '/brain-care/:anchor?',
                        exact: true,
                        component: renderWithSuspense(BrainCare)
                    },
                    {
                        path: '/about/:anchor?',
                        exact: true,
                        component: renderWithSuspense(About)
                    },
                    {
                        path: '/video',
                        exact: true,
                        component: renderWithSuspense(AboutVideo)
                    },
                    {
                        path: '/stores',
                        exact: true,
                        component: renderWithSuspense(Stores)
                    },
                    {
                        path: '/contact-us',
                        exact: true,
                        component: renderWithSuspense(ContactUs)
                    },
                    {
                        path: '/site-map',
                        exact: true,
                        component: renderWithSuspense(SiteMap)
                    },
                    {
                        path: '/terms-and-conditions',
                        exact: true,
                        component: renderWithSuspense(TermsAndConditions)
                    },
                    {
                        path: '/privacy-policy',
                        exact: true,
                        component: renderWithSuspense(PrivacyPolicy)
                    },
                    {
                        path: '/cookies',
                        exact: true,
                        component: renderWithSuspense(Cookies)
                    },
                    {
                        path: '/',
                        exact: true,
                        component: renderWithSuspense(Home)
                    }
                ];

                const { pageTitle, currentLanguage, onMobileMenu } = this.state;
                // var path = `/:lang(${config.languages.join('|')})`;
                var path = `/:lang(${config.languages[0]})`;
                var seoData = {
                    title: this.props.t('SITE_TITLE'),
                    desc: this.props.t('Common:META_DESCPTIOIN'),
                    languagePrefix: currentLanguage,
                    slug: ''
                };
                return (
                    <div id="app-root" className={isWebpSupported() ? 'webp' : 'no-webp'}>
                        <div className="application">
                            <Seo data={seoData} />

                            <Header pageTitle={pageTitle} />
                            <div id="app-main" className={currentLanguage === 'en' ? 'lang-en' : ''}>
                                <Switch>
                                    {routesObj.map((route, index) => (
                                        <Route
                                            key={shortid.generate()}
                                            path={`${path}${route.path}`}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    ))}

                                    {routesObj.map((route, index) => (
                                        <Route
                                            key={shortid.generate()}
                                            path={route.path}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    ))}
                                    {/*} <Route
                                        render={({ location: { pathname } }) => {
                                            var pathnames = pathname.split('/');
                                            var target = `/${pathnames[pathnames.length - 1]}`;
                                            return <Redirect to={prefixLng(target)} />;
                                        }}
                                    /> 
                                    <Redirect to="/" />
                                */}

                                    <Route component={PageNotFound} />
                                </Switch>
                            </div>
                            {!isMobile && <HelpBox />}
                            <Footer />
                        </div>
                    </div>
                );
            }

            isMobileWidth() {
                return this.isMobile;
            }

            scrollTo(anchor) {
                if (anchor === 'scrollToTop') {
                    window.scrollTo(0, 0);
                } else if (anchor !== undefined && anchor !== null) {
                    setTimeout(() => {
                        var target = document.getElementById(anchor);
                        if (target) {
                            var yPos = target.offsetTop - document.getElementById('header').offsetHeight;
                            window.scrollTo(0, yPos);
                        }
                    }, 100);
                }
            }
        }
    )
);
