import i18n from 'i18next';
import config from './config';

export function prefixLng(path) {
    var split = config.languages.indexOf(path.split('/')[1]);
    if (path[0] === '/' && split < 0) {
        var lng = i18n.getFixedT(i18n.language, 'Common')('LANG');
        var initPath = lng === 'en' ? '/en' : '';
        return initPath + path;
    }
    return path;
}

export const Constants = {
    allowed_locales: ['zh', 'en'],
    default_locale: 'zh'
};

export function shallowCompare(instance, nextProps, nextState) {
    return !shallowEqual(instance.props, nextProps) || !shallowEqual(instance.state, nextState);
}

export function shallowEqual(objA, objB) {
    if (objA === objB) {
        return true;
    }

    if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
        return false;
    }

    var keysA = Object.keys(objA);
    var keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
        return false;
    }

    // Test for A's keys different from B.
    var bHasOwnProperty = hasOwnProperty.bind(objB);
    for (var i = 0; i < keysA.length; i++) {
        if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
            return false;
        }
    }

    return true;
}
