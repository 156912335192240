import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import config from '../config';

const Seo = props => {
    const { data } = props;
    const seoConfig = config.seo;
    const postTitle = data.title;
    const postDescription = data.desc;
    const postSlug = data.slug;
    const lng = data.languagePrefix === 'en' ? '/en' : '';
    const image = seoConfig.siteImage;
    const url = seoConfig.siteUrl + lng + '/' + postSlug;

    return (
        <Helmet>
            {/* General tags */}
            <meta charSet="utf-8" />
            <title>{postTitle}</title>
            <meta name="title" content={postTitle} />
            <meta name="description" content={postDescription} />
            <link rel="canonical" href={url} />
            {/* OpenGraph tags */}
            <meta name="og:url" content={url} />
            <meta name="og:title" content={postTitle} />
            <meta name="og:description" content={postDescription} />
            <meta name="og:image" content={image} />
            <meta property="og:image:width" content="1024" />
            <meta property="og:image:height" content="600" />
            <meta name="og:type" content="website" />
            {/*<meta name="fb:app_id" content={seoConfig.appId} /> */}
            {/* Twitter Card tags 
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content={seoConfig.authorTwitterAccount ? seoConfig.authorTwitterAccount : ''} */}
            />
        </Helmet>
    );
};

Seo.propTypes = {
    data: PropTypes.object
};

export default Seo;
