import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { prefixLng } from '../util';

export default function() {
    const { t } = useTranslation('Footer');
    
    return (
        <div id="footer" className="footer max-view">
          <div className="inner-container">
              <div className="footer--bottom">
              <div className="footer__menu">
              <ul>
                  <li>
                      <Link
                          className="footer__link"
                          to={prefixLng('/contact-us')}>
                          {t('LINK_CONTACTUS')}
                      </Link>
                  </li>
                  <li className=""></li>
                  <li>
                      <Link
                          className="footer__link"
                          to={prefixLng('/site-map')}>
                          {t('LINK_SITEMAP')}
                      </Link>
                  </li>
                  <li className=""></li>
                  <li>
                      <Link
                          className="footer__link"
                          to={prefixLng('/terms-and-conditions')}>
                          {t('LINK_TNC')}
                      </Link>
                  </li>
                  <li className=""></li>
                  <li>
                      <Link
                          className="footer__link"
                          to={prefixLng('/privacy-policy')}>
                          {t('LINK_PRIVACY')}
                      </Link>
                  </li>
                  <li className=""></li>
                  <li>
                      <Link
                          className="footer__link"
                          to={prefixLng('/cookies')}>
                          {t('LINK_COOKIES')}
                      </Link>
                  </li>
              </ul>
          </div>
                <div className="copyright">
                <p>{t('COPYRIGHT')}</p>
                </div>
              </div>
              
          </div>
        </div>
    );
}
