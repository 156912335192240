import React from 'react';
import logo from '../assets/images/for-all-page/footer_logo.png';
import { useTranslation } from 'react-i18next';

export default function() {
    const { t } = useTranslation('Common');

    return (
        <div className="white-bg">
            {/* bottom nav */}
            <div className="mw-content bottom-nav py-md-50 px-10">
                <div className="row px-md-10">
                    <div className="bottom-nav-item text-center">
                        <img src={logo} alt="Souvenaid Logo" alt="Main Logo" />
                    </div>
                    <div className="bottom-nav-item text-center">
                        <a
                            href="https://www.nutricia.com/"
                            className="font-grey font-md-32"
                            target="_blank"
                            rel="noopener">
                            {t('ABOUT_NUTRICIA')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
