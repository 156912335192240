import React from 'react';
import { FacebookProvider, Page } from 'react-facebook';

export default function() {
    return (
        <FacebookProvider appId="1310070145701340" width="300" height="100">
            <Page href="https://www.facebook.com/SouvenaidHK/" tabs="timeline" />
        </FacebookProvider>
    );
}
