import React from 'react';

export default class extends React.Component {
    constructor(props) {
        super(props);
        if (props.t) {
        }

        this._updateScrollKeys = {};
    }

    componentWillReceiveProps(newProps) {
        if (newProps.t !== this.props.t) {
            if (this.onLanguageChanged) {
                this.onLanguageChanged(newProps.i18n.language, newProps.t);
            }
        }
    }

    resetScrollOnStateChange(...keys) {
        for (var i of keys) {
            this._updateScrollKeys[i] = true;
        }
    }
}
