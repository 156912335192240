import React from 'react';
import { withTranslation } from 'react-i18next';
import { app } from '../App';
import BaseController from '../components/BaseController';
import BottomNav from '../components/BottomNav';
import TagManager from 'react-gtm-module';
import Helmet from 'react-helmet';

const tagManagerArgs = {
    dataLayer: {
        userId: '011',
        userProject: 'souvenaid',
        page: '404'
    },
    dataLayerName: 'PageDataLayer'
};

export default withTranslation('PageNotFound')(
    class extends BaseController {
        constructor(props) {
            super(props);
            this.state = {
                currentLanguage: props.i18n.language
            };
        }

        componentDidMount() {
            setTimeout(function() {
                app.redirect('/');
            }, 7000);
        }

        render() {
            TagManager.dataLayer(tagManagerArgs);
            const { t } = this.props;
            const currentLanguage = this.props.i18n.language === 'en' ? ' lang-en' : '';

            return (
                <div className="main-content max-view white-bg">
                    <Helmet>
                        <meta name="prerender-status-code" content="404" />
                    </Helmet>
                    <div className="py-md-50 px-md-30 px-10 sub-page">
                        <div className="mw-content py-md-40 px-md-30 px-10 light-grey-bg">
                            <div className="black font-weight-bold">
                                <div
                                    id="404-static"
                                    dangerouslySetInnerHTML={{ __html: t('Common:ERROR_404_NOT_FOUND') }}></div>
                                <p>{t('Common:ERROR_404_NOT_FOUND_REDIRECT')}</p>
                            </div>
                        </div>
                    </div>

                    {/* bottom nav */}
                    <BottomNav />
                </div>
            );
        }
    }
);
