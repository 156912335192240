import React from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { prefixLng } from '../util';
import config from '../config';
import logo from '../assets/images/for-all-page/nav_logo.svg';
import { app } from '../App';
import { isMobile, isIOS } from 'react-device-detect';

export default withTranslation('Header')(
    class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                currentLanguage: props.i18n.language,
                languages: config.languages,
                toggleMobileMenuBtn: true,
                scrollToEle: ''
            };

            i18n.on('languageChanged', lng => {
                this.setState({
                    currentLanguage: lng
                });
            });

            this.getActivePage = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
            this.activePage = 'Home';
        }

        toggleMobileMenu = e => {
            var menuBtn = document.getElementById('MenuButton');
            var active = app.hasClass(menuBtn, 'active');

            if (active) {
                menuBtn.classList.remove('active');
            } else {
                menuBtn.classList.add('active');
            }

            app._onMobileMenu(!active);
        };

        closeMobileMenu = e => {
            var menuBtn = document.getElementById('MenuButton');
            menuBtn.classList.remove('active');
            app._onMobileMenu(false);
        };

        addActiveClass = e => {
            app._onMobileMenu(false);
            app.scrollTo('scrollToTop');
        };

        render() {
            const { t, pageTitle } = this.props;
            const { currentLanguage, languages } = this.state;
            var langList = [];
            languages.map(lang => {
                langList.push(
                    <li className={lang === currentLanguage ? 'active' : ''} key={lang}>
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                if (lang !== currentLanguage) {
                                    i18n.changeLanguage(lang).catch(err => {
                                        if (err) {
                                            console.log('something went wrong loading', err);
                                        }
                                    });
                                }
                            }}>
                            {lang === 'zh' ? '繁' : 'EN'} {/* {key} */}
                        </a>
                    </li>
                );
            });

            this.activePage = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];

            if (window.location.pathname.split('/').length > 2) {
                this.activePage = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
            }

            return (
                <div className="header" id="header">
                    <div className="header--top vw-md-100">
                        {/* <div className="top-bar">
                            <a href="https://shop.souvenaid.com.hk/pages/charitypack" target="_blank">
                                <div class="shopping-cart"></div>
                                選購養腦慈善禮盒，支持香港認知障礙症協會
                                <div class="arrow"></div>
                            </a>
                        </div> */}

                        <div className="inner-container pr-10 position-relative">
                            <div className="breadcrumb">
                                <Link
                                    className="logo d-block d-md-none"
                                    to={prefixLng('/')}
                                    onClick={this.addActiveClass}>
                                    <img src={logo} onClick={this.closeMobileMenu} alt="Souvenaid Logo" />
                                </Link>
                                <a href="tel: 34605498" className="d-block d-md-none">
                                    <div className="m-phone m-help-icon"> </div>
                                </a>
                                <a
                                    href="https://shop.souvenaid.com.hk"
                                    className="d-block d-md-none"
                                    target="_blank"
                                    rel="noopener">
                                    <div className="m-shopping-cart m-help-icon"> </div>
                                </a>
                                <div id="MenuButton" className={'menu-btn'} onClick={this.toggleMobileMenu}>
                                    <div className="line"> </div>
                                    <div className="line"> </div>
                                    <div className="line"> </div>
                                </div>
                            </div>
                            <div className="lang-switch d-none d-md-block">
                                <ul> {langList} </ul>
                            </div>
                            <a href="https://shop.souvenaid.com.hk/pages/mci-memory-assessment" target="_blank">
                                <div
                                    className={`free-trail-btn ${currentLanguage === 'en' ? 'en' : ''}`}
                                    dangerouslySetInnerHTML={{ __html: t('Header:NAV_START_TRIAL') }}></div>
                                <span></span>
                            </a>
                            <a href="https://www.facebook.com/SouvenaidHK/" target="_blank" rel="noopener">
                                <div className="fb-logo position-absolute"> </div>
                            </a>
                        </div>
                    </div>
                    <div className="header--bottom vw-md-100 z-999">
                        <div className="inner-container">
                            <nav>
                                <ul
                                    className={
                                        this.state.toggleMobileMenuBtn
                                            ? isMobile && isIOS
                                                ? currentLanguage === 'en'
                                                    ? 'active is-ios lang-en'
                                                    : 'is-ios active'
                                                : currentLanguage === 'en'
                                                ? 'active lang-en'
                                                : 'active'
                                            : currentLanguage === 'en'
                                            ? 'active lang-en'
                                            : ''
                                    }>
                                    <li className="nav-logo" onClick={this.closeMobileMenu}>
                                        <Link className="logo" to={prefixLng('/')}>
                                            <img src={logo} alt="Souvenaid Header Logo" />
                                        </Link>
                                    </li>
                                    <li
                                        className={
                                            this.activePage === 'home'
                                                ? 'active d-md-none d-block'
                                                : 'd-md-none d-block'
                                        }
                                        onClick={this.closeMobileMenu}>
                                        <Link
                                            className="breadcrumb__link"
                                            to={prefixLng('/')}
                                            onClick={this.addActiveClass}>
                                            {t('NAV_HOME')}
                                        </Link>
                                    </li>
                                    <li
                                        className={
                                            this.activePage === 'dementia'
                                                ? currentLanguage === 'en'
                                                    ? 'active lang-en'
                                                    : 'active'
                                                : currentLanguage === 'en'
                                                ? 'lang-en'
                                                : ''
                                        }
                                        onMouseEnter={this.activePage === 'dementia' ? this.toggleHover : undefined}
                                        onMouseLeave={this.activePage === 'dementia' ? this.toggleHover : undefined}
                                        onClick={this.closeMobileMenu}>
                                        <Link
                                            className="breadcrumb__link"
                                            to={prefixLng('/dementia')}
                                            onClick={this.addActiveClass}>
                                            {t('NAV_DEGENERATIVE_BRAIN')}
                                            <span
                                                className={
                                                    this.activePage === 'dementia' ? 'menu-arrow active' : 'menu-arrow'
                                                }></span>
                                        </Link>
                                        {/* sub menu */}
                                        <div className="position-absolute z-1 submenu-container">
                                            <div className="sub-menu position-relative sub-menu-brain-care">
                                                <div className="mw-content col">
                                                    <div className="link">
                                                        <Link
                                                            to={prefixLng('/dementia/about-dementia')}
                                                            className="violet"
                                                            onClick={e => {
                                                                app.scrollTo('about-dementia');
                                                            }}>
                                                            <span> {t('DEGENERATIVE_BRAIN_SUBMENU_ITEM_2')} </span>
                                                        </Link>
                                                    </div>
                                                    <div className="link">
                                                        <Link
                                                            to={prefixLng('/dementia/self-assessment')}
                                                            className="violet"
                                                            onClick={e => {
                                                                app.scrollTo('self-assessment');
                                                            }}>
                                                            {t('DEGENERATIVE_BRAIN_SUBMENU_ITEM_3')}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="d-none d-md-block">
                                        <div className="menu-seperator"> </div>
                                    </li>
                                    <li
                                        className={
                                            this.activePage === 'brain-care'
                                                ? currentLanguage === 'en'
                                                    ? 'active lang-en'
                                                    : 'active'
                                                : currentLanguage === 'en'
                                                ? 'lang-en'
                                                : ''
                                        }
                                        onMouseEnter={this.activePage === 'brain-care' ? this.toggleHover : undefined}
                                        onMouseLeave={this.activePage === 'brain-care' ? this.toggleHover : undefined}
                                        onClick={this.closeMobileMenu}>
                                        <Link
                                            className="breadcrumb__link"
                                            to={prefixLng('/brain-care')}
                                            onClick={this.addActiveClass}>
                                            {t('NAV_BRAIN_CARE')}
                                            <span
                                                className={
                                                    this.activePage === 'brain-care'
                                                        ? 'menu-arrow active'
                                                        : 'menu-arrow'
                                                }></span>
                                        </Link>
                                        {/* sub menu */}
                                        <div className="position-absolute  z-1 submenu-container">
                                            <div className="sub-menu position-relative sub-menu-deg-brain max-view">
                                                <div className="mw-content col">
                                                    <div className="link">
                                                        <Link
                                                            to={prefixLng('/brain-care/healthy-brain-lifestyle')}
                                                            className="violet"
                                                            onClick={e => {
                                                                app.scrollTo('healthy-brain-lifestyle');
                                                            }}>
                                                            {t('BRAIN_CARE_SUBMENU_ITEM_1')}
                                                        </Link>
                                                    </div>
                                                    <div className="link">
                                                        <Link
                                                            to={prefixLng('/brain-care/medical-nutrition-intervention')}
                                                            className="violet"
                                                            onClick={e => {
                                                                app.scrollTo('medical-nutrition-intervention');
                                                            }}>
                                                            {t('BRAIN_CARE_SUBMENU_ITEM_2')}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="d-none d-md-block">
                                        <div className="menu-seperator"> </div>
                                    </li>
                                    <li
                                        className={
                                            this.activePage === 'about'
                                                ? currentLanguage === 'en'
                                                    ? 'active lang-en'
                                                    : 'active'
                                                : currentLanguage === 'en'
                                                ? 'lang-en'
                                                : ''
                                        }
                                        onMouseEnter={this.activePage === 'about' ? this.toggleHover : undefined}
                                        onMouseLeave={this.activePage === 'about' ? this.toggleHover : undefined}
                                        onClick={this.closeMobileMenu}>
                                        <Link
                                            className="breadcrumb__link"
                                            to={prefixLng('/about')}
                                            onClick={this.addActiveClass}>
                                            {t('NAV_ABOUT')}
                                            <span
                                                className={
                                                    this.activePage === 'about' ? 'menu-arrow active' : 'menu-arrow'
                                                }
                                                onClick={e => {
                                                    app.scrollTo('undefined');
                                                }}></span>
                                        </Link>
                                        {/* sub menu */}
                                        <div className="position-absolute  z-1 submenu-container">
                                            <div className="sub-menu position-relative max-view">
                                                <div className="mw-content col">
                                                    <div className="link">
                                                        <Link
                                                            to={prefixLng('/about/patented-medical-nutrition-formula')}
                                                            className="violet"
                                                            onClick={e => {
                                                                app.scrollTo('patented-medical-nutrition-formula');
                                                            }}>
                                                            {t('ABOUT_SUBMENU_ITEM_1')}
                                                        </Link>
                                                    </div>
                                                    <div className="link">
                                                        <Link
                                                            to={prefixLng('/about/clinically-proven')}
                                                            className="violet"
                                                            onClick={e => {
                                                                app.scrollTo('clinically-proven');
                                                            }}>
                                                            {t('ABOUT_SUBMENU_ITEM_2')}
                                                        </Link>
                                                    </div>
                                                    <div className="link">
                                                        <Link
                                                            to={prefixLng('/about/frequently-asked-questions')}
                                                            className="violet"
                                                            onClick={e => {
                                                                app.scrollTo('frequently-asked-questions');
                                                            }}>
                                                            {t('ABOUT_SUBMENU_ITEM_3')}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="d-none d-md-block">
                                        <div className="menu-seperator"> </div>
                                    </li>
                                    <li
                                        className={
                                            this.activePage === 'stores'
                                                ? currentLanguage === 'en'
                                                    ? 'active lang-en'
                                                    : 'active'
                                                : currentLanguage === 'en'
                                                ? 'lang-en'
                                                : ''
                                        }
                                        onClick={this.closeMobileMenu}>
                                        <Link className="breadcrumb__link" to={prefixLng('/stores')}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: t('NAV_SOTRES')
                                                }}></span>
                                        </Link>
                                    </li>
                                    <li className="d-md-none d-block" onClick={this.closeMobileMenu}>
                                        <a
                                            href="https://shop.souvenaid.com.hk/pages/%E7%B6%B2%E7%AB%99-%E7%99%BB%E8%A8%98%E7%B4%A2%E5%8F%96%E8%A9%A6%E9%A3%B2%E8%A3%9D?ref=powr-io"
                                            className="breadcrumb__link"
                                            target="_blank"
                                            rel="noopener">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: t('NAV_START_TRIAL')
                                                }}></span>
                                        </a>
                                    </li>
                                    <li
                                        className={
                                            this.activePage === 'contact-us'
                                                ? 'active d-block d-md-none'
                                                : 'd-block d-md-none'
                                        }
                                        onClick={this.closeMobileMenu}>
                                        <Link className="breadcrumb__link" to={prefixLng('/contact-us')}>
                                            {t('NAV_CONTACT_US')}
                                        </Link>
                                    </li>
                                    <li className="d-block d-md-none pt-90" onClick={this.closeMobileMenu}>
                                        <Link className="breadcrumb__link" to={prefixLng('/contact-us')}></Link>
                                    </li>
                                    <li>
                                        <div className="lang-switch d-md-none d-block">
                                            <ul onClick={this.closeMobileMenu}> {langList} </ul>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            );
        }
    }
);
