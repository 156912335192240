import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ResourceEN from './i18n/en';
import ResourceZH from './i18n/zh';
import { Constants } from './util';

/* eslint import/no-webpack-loader-syntax: off */
import tnc_en_html from 'html-loader!./assets/html/tnc.en.html';
import tnc_zh_html from 'html-loader!./assets/html/tnc.zh.html';
import private_policy_en_html from 'html-loader!./assets/html/private-policy.en.html';
import private_policy_zh_html from 'html-loader!./assets/html/private-policy.zh.html';
import cookies_en_html from 'html-loader!./assets/html/cookies.en.html';
import cookies_zh_html from 'html-loader!./assets/html/cookies.zh.html';

const i18nReady = i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            fallbackLng: ['zh', 'en'],
            debug: false,
            ns: ['translations'],
            defaultNS: 'translations',
            resources: { ...ResourceEN, ...ResourceZH },
            detection: {
                order: ['path'],
                lookupFromPathIndex: 0
            }
        },
        () => {
            // Why the fuck this doesnt work automatically with fallbackLng ??
            if (!Constants.allowed_locales.includes(i18n.language)) {
                i18n.changeLanguage(Constants.default_locale);
            }
            return;
        }
    );

i18n.addResource('en', 'StaticHTML', 'TNC', tnc_en_html);
i18n.addResource('zh', 'StaticHTML', 'TNC', tnc_zh_html);
i18n.addResource('en', 'StaticHTML', 'PRIVATE_POLICY', private_policy_en_html);
i18n.addResource('zh', 'StaticHTML', 'PRIVATE_POLICY', private_policy_zh_html);
i18n.addResource('en', 'StaticHTML', 'COOKIES', cookies_en_html);
i18n.addResource('zh', 'StaticHTML', 'COOKIES', cookies_zh_html);
