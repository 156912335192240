import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/common.scss';
import './init-i18n';
import { App } from './App';
// import { BrowserRouter } from 'react-router-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>,
    rootElement
  );
}
else {
  ReactDOM.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>,
    rootElement);
}


// const root = (
//     <BrowserRouter basename={process.env.PUBLIC_URL}>
//         <App />
//     </BrowserRouter>
// );

// ReactDOM.render(root, document.getElementById('root'));

serviceWorker.unregister();
